footer.page-footer h2 {
  font-size: 1.5rem;
  margin: 0;
}

footer.page-footer h3 {
  font-size: 1.25rem;
  margin: 0;
}

footer.page-footer .footer-copyright p {
  margin: 0 auto;
}

footer .copyright, footer .footer-copyright a {
  padding: 0.5rem;
}

footer a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

footer #social-media i {
  margin: 0.5rem;
}