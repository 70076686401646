@import url(https://fonts.googleapis.com/css?family=Raleway|Roboto|Roboto+Condensed|Roboto+Mono&display=swap);
/* Styles for the entire browser window */

html {
  margin: 0;
  font-family: "Roboto","Helvetica Neue", sans-serif;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  width: 100%;
}

body {
  word-break: break-word;
  font-size: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

h1, h2, h3, h4, h5, h6, .brand-logo {
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  }
  
h1 {
	font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 { 
  font-size: 1.75rem;
}

h5 { 
  font-size: 1.5rem;
}

h6 { 
  font-size: 1.25rem;
}

code {
  font-family: "Roboto Mono", "Courier New", monospace;
}

a {
  transition: background-color .3s;
}

nav ul li a, .dropdown-content li > a, .sidenav li > a, footer.page-footer a, footer .footer-copyright a {
  font-weight: 500;
}

strong {
  font-weight: 700;
}
/* Styles for the entire React app */
.protocol {
  display: none;
}
.carousel .carousel-item .slide-text {
  padding: 3rem 2rem;
  background-color: rgba(55, 71, 79, 0.25);
  display: flex;
  flex-direction: column;
}

.carousel .carousel-item .slide-text.slide-text-left {
  justify-content: left;
}

.carousel .carousel-item .slide-text.slide-text-center {
  justify-content: center;
}

.carousel .carousel-item .slide-text.slide-text-right {
  justify-content: right;
}

.carousel .carousel-item .slide-text.slide-text-top {
  align-items: flex-start;
}

.carousel .carousel-item .slide-text.slide-text-middle {
  align-items: center;
}

.carousel .carousel-item .slide-text.slide-text-bottom {
  align-items: flex-end;
}

.carousel .carousel-item .slide-text.slide-full,
.carousel .carousel-item .slide-text.slide-half {
  height: 100%;
}

.carousel .carousel-item .slide-text.slide-half.slide-top,
.carousel .carousel-item .slide-text.slide-half.slide-bottom {
  height: 50%;
}

.carousel .carousel-item .slide-text.slide-half.slide-left,
.carousel .carousel-item .slide-text.slide-half.slide-right {
  width: 50%;
}

.carousel .carousel-item .slide-text.slide-half.slide-right {
  margin-left: 50%;
}

.carousel .carousel-item .slide-text.slide-half.slide-bottom {
  margin-top: 50%;
}

.carousel .carousel-item .slide-text.slide-black {
  background-color: rgba(0, 0, 0, 0.25);
}

.carousel .carousel-item .slide-text.slide-white {
  background-color: rgba(255, 255, 255, 0.25);
}

.carousel .carousel-item .slide-text.slide-red {
  background-color: rgba(255, 0, 0, 0.25);
}

.carousel .carousel-item .slide-text.slide-green {
  background-color: rgba(0, 255, 0, 0.25);
}

.carousel .carousel-item .slide-text.slide-blue {
  background-color: rgba(0, 0, 255, 0.25);
}

.carousel .carousel-item .slide-text.slide-yellow {
  background-color: rgba(255, 255, 0, 0.25);
}

.carousel .carousel-item .slide-text.slide-purple {
  background-color: rgba(255, 0, 255, 0.25);
}

.carousel .carousel-item .slide-text.slide-cyan {
  background-color: rgba(0, 255, 255, 0.25);
}

.carousel.slider-home {
  height: calc(100vh - 56px) !important;
}

.carousel.slider-home .carousel-item {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

@media only screen and (max-width: 600px) {
  .carousel .carousel-item .slide-text.slide-full,
  .carousel .carousel-item .slide-text.slide-half,
  .carousel .carousel-item .slide-text.slide-half.slide-top,
  .carousel .carousel-item .slide-text.slide-half.slide-bottom,
  .carousel .carousel-item .slide-text.slide-half.slide-left,
  .carousel .carousel-item .slide-text.slide-half.slide-right {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding: 0 2rem;
  }
}
.msg-status {
  text-align: center;
  font-size: 1.25rem;
}
.collection .collection-header {
  padding: 0.25rem;
}
label.input-list {
  display: block;
  padding: 0;
}

label.input-line {
  display: inline;
  padding: 0 1rem 0 0;
}

.select-wrapper .dropdown-content li > span {
  color: rgba(84, 110, 122, 1);
}

.input-field, .input-field input, .input-field :not(textarea) + label {
  -webkit-margin-before: 1rem !important;
          margin-block-start: 1rem !important;
}

[type="radio"]:checked + span:after {
  border: 2px solid blue;
}

[type="radio"]:checked + span:after, 
[type="radio"].with-gap:checked + span:after,
[type="checkbox"].filled-in:checked + span:not(.lever):after {
  background-color: blue;
}

.radio-button input[type="radio"]:not(:checked) + span,
.radio-button input[type="radio"]:checked + span {
  border-radius: 10px;
  padding: 1rem;
  height: unset;
  line-height: unset;
}

.radio-button input[type="radio"]:checked + span,
.radio-button:hover input[type="radio"]:not(:checked) + span {
  background-color: rgba(84, 110, 122, 0.7);
  color: white;
}

.radio-button input[type="radio"]:checked + span {
  background-color: rgba(84, 110, 122, 1);
}

.radio-button input[type="radio"],
.radio-button input[type="radio"]:not(:checked) + span:before,
.radio-button input[type="radio"]:not(:checked) + span:after,
.radio-button input[type="radio"]:checked + span:after {
  display: none;
}
.switch :not(p) {
  -webkit-margin-before: 1rem !important;
          margin-block-start: 1rem !important;
}

.switch p {
  -webkit-margin-after: unset;
          margin-block-end: unset;
}

.switch label .lever:before, .switch label .lever:after {
  background-color: rgba(236, 239, 241, 1);
}

.switch label input[type=checkbox]:checked+.lever:after{
  background-color:rgba(255, 193, 7, 1);
}

nav .brand-logo {
  padding: 0 1rem;
  width: 100%;
  text-align: center;
}

.dropdown-content {
  min-width: 10rem;
}

.dropdown-content li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

#nav-mobile .mobile-title {
  font-size: 1.5rem;
  padding: 1rem;
  text-align: center;
  margin: 0;
}

@media only screen and (min-width: 992px) {
  nav .brand-logo {
    text-align: left;
    width: auto;
  }
}
footer.page-footer h2 {
  font-size: 1.5rem;
  margin: 0;
}

footer.page-footer h3 {
  font-size: 1.25rem;
  margin: 0;
}

footer.page-footer .footer-copyright p {
  margin: 0 auto;
}

footer .copyright, footer .footer-copyright a {
  padding: 0.5rem;
}

footer a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

footer #social-media i {
  margin: 0.5rem;
}
