/* Styles for the entire browser window */
@import url('https://fonts.googleapis.com/css?family=Raleway|Roboto|Roboto+Condensed|Roboto+Mono&display=swap');

html {
  margin: 0;
  font-family: "Roboto","Helvetica Neue", sans-serif;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  width: 100%;
}

body {
  word-break: break-word;
  font-size: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

h1, h2, h3, h4, h5, h6, .brand-logo {
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  }
  
h1 {
	font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 { 
  font-size: 1.75rem;
}

h5 { 
  font-size: 1.5rem;
}

h6 { 
  font-size: 1.25rem;
}

code {
  font-family: "Roboto Mono", "Courier New", monospace;
}

a {
  transition: background-color .3s;
}

nav ul li a, .dropdown-content li > a, .sidenav li > a, footer.page-footer a, footer .footer-copyright a {
  font-weight: 500;
}

strong {
  font-weight: 700;
}